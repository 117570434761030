import { message } from 'antd';
import React, { createContext, useContext, useEffect, useState } from 'react';
import userService from 'services/auth-service';

type AuthContextType = {
	user: User | false;
	setUser: (value: any) => void;
	lastRoute: string;
	setLastRoute: (value: any) => void;
	logout: () => void;
	loading?: boolean;
};

const AuthContext = createContext<AuthContextType>({
	user: false,
	setUser: (value: any) => {},
	lastRoute: '',
	setLastRoute: (value: any) => {},
	logout: () => {},
	loading: true,
});

const { Provider } = AuthContext;

export const useAuth = () => useContext<AuthContextType>(AuthContext);

type Privileges = {
	id: string;
	name: string;
};

type User = {
	token: string;
	privileges: string[];
	organizationId?: string;
};

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const [user, _setUser] = useState<User | false>(false);
	const [lastRoute, setLastRoute] = useState('');
	const [loading, setLoading] = useState(true);

	const setUser = async (user: { token: string }) => {
		if (!user) {
			return _setUser(false);
		}

		localStorage.setItem('tms-token', user.token);

		try {
			const { data } = await userService.getUserProfile();

			const privileges = data.roleResponses.reduce((acc: string[], role: any) => {
				return [...acc, ...role.privileges];
			}, []);

			_setUser({
				token: user.token,
				privileges: privileges,
				organizationId: data.serviceProviderId ? data.serviceProviderId : data.organizationId,
			});
		} catch (error) {
			message.error('Something went wrong!');
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		handleSessionRestore();

		async function handleSessionRestore() {
			const token = localStorage.getItem('tms-token');

			if (token) {
				try {
					const { data } = await userService.getUserProfile();

					const privileges = data.roleResponses.reduce((acc: string[], role: any) => {
						return [...acc, ...role.privileges];
					}, []);

					_setUser({
						token: token,
						privileges: privileges,
						organizationId: data.organizationId || data.serviceProviderId,
					});
				} catch (error) {
				} finally {
					setLoading(false);
				}
			} else {
				setLoading(false);
			}
		}
	}, []);

	const logout = () => {
		localStorage.removeItem('tms-token');
		_setUser(false);
	};

	const value = {
		user,
		setUser,
		lastRoute,
		setLastRoute,
		logout,
		loading,
	};

	return <Provider value={value}>{!loading && children}</Provider>;
};

export default AuthProvider;
