import { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './auth-provider';

export type Privilage =
	| 'SELF_READ'
	| 'SELF_WRITE'
	| 'USER_READ'
	| 'USER_WRITE'
	| 'ROLE_READ'
	| 'ROLE_WRITE'
	| 'ORGANIZATION_READ'
	| 'ORGANIZATION_WRITE'
	| 'SERVICE_PROVIDER_READ'
	| 'SERVICE_PROVIDER_WRITE'
	| 'SERVICE_PROVIDER_UPDATE'
	| 'DRIVER_WRITE'
	| 'DRIVER_READ'
	| 'DRIVER_UPDATE'
	| 'DRIVER_ONLINE_STATUS_UPDATE'
	| 'DRIVER_APPROVE'
	| 'VEHICLE_WRITE'
	| 'VEHICLE_READ'
	| 'VEHICLE_APPROVE'
	| 'HELPER_WRITE'
	| 'HELPER_READ'
	| 'HELPER_APPROVE'
	| 'LOCATION_READ'
	| 'LOCATION_WRITE'
	| 'CLIENT_USER_READ'
	| 'CLIENT_USER_WRITE'
	| 'SERVICE_PROVIDER_USER_READ'
	| 'SERVICE_PROVIDER_USER_WRITE'
	| 'BOOKING_ORDER_READ'
	| 'BOOKING_ORDER_WRITE'
	| 'BOOKING_ORDER_FILE_UPLOAD'
	| 'ZONE_READ'
	| 'ZONE_WRITE'
	| 'ROUTE_READ'
	| 'ROUTE_WRITE'
	| 'DOCK_READ'
	| 'DOCK_WRITE'
	| 'SETTINGS_READ'
	| 'SETTINGS_WRITE';

export type PrivilageList = Privilage[];

export type PrivilageMatrix = {
	user: {
		read: boolean;
		write: boolean;
	};
	clientUser: {
		read: boolean;
		write: boolean;
	};
	serviceProviderUser: {
		read: boolean;
		write: boolean;
	};
	driver: {
		read: boolean;
		write: boolean;
		approve: boolean;
	};
	helper: {
		read: boolean;
		write: boolean;
		approve: boolean;
	};
	role: {
		read: boolean;
		write: boolean;
	};
	vehicle: {
		read: boolean;
		write: boolean;
		approve: boolean;
	};

	client: {
		read: boolean;
		write: boolean;
	};
	location: {
		read: boolean;
		write: boolean;
	};
	serviceProvider: {
		read: boolean;
		write: boolean;
	};
	order: {
		read: boolean;
		write: boolean;
	};
	zone: {
		read: boolean;
		write: boolean;
	};
	routes: {
		read: boolean;
		write: boolean;
	};
	dock: {
		read: boolean;
		write: boolean;
	};
	settings: {
		read: boolean;
		write: boolean;
	};
};

export const getPrivilageMatrixFromPrivilageList = (privileges: PrivilageList): PrivilageMatrix => {
	let privilageMatrix: PrivilageMatrix = {
		user: { read: false, write: false },
		clientUser: { read: false, write: false },
		serviceProviderUser: { read: false, write: false },
		driver: { read: false, write: false, approve: false },
		helper: { read: false, write: false, approve: false },
		role: { read: false, write: false },
		vehicle: { read: false, write: false, approve: false },
		client: { read: false, write: false },
		location: { read: false, write: false },
		serviceProvider: { read: false, write: false },
		order: { read: false, write: false },
		zone: { read: false, write: false },
		routes: { read: true, write: false },
		dock: { read: false, write: false },
		settings: { read: false, write: false },
	};

	for (let privilage of privileges) {
		if (privilage === 'USER_READ') privilageMatrix.user.read = true;
		if (privilage === 'USER_WRITE') privilageMatrix.user.write = true;

		if (privilage === 'CLIENT_USER_READ') privilageMatrix.clientUser.read = true;
		if (privilage === 'CLIENT_USER_WRITE') privilageMatrix.clientUser.write = true;

		if (privilage === 'SERVICE_PROVIDER_USER_READ') privilageMatrix.serviceProviderUser.read = true;
		if (privilage === 'SERVICE_PROVIDER_USER_WRITE') privilageMatrix.serviceProviderUser.write = true;

		if (privilage === 'DRIVER_READ') privilageMatrix.driver.read = true;
		if (privilage === 'DRIVER_WRITE') privilageMatrix.driver.write = true;
		if (privilage === 'DRIVER_APPROVE') {
			privilageMatrix.driver.read = true;
			privilageMatrix.driver.approve = true;
		}

		if (privilage === 'HELPER_READ') privilageMatrix.helper.read = true;
		if (privilage === 'HELPER_WRITE') privilageMatrix.helper.write = true;
		if (privilage === 'HELPER_APPROVE') {
			privilageMatrix.helper.approve = true;
			privilageMatrix.helper.read = true;
		}

		if (privilage === 'ROLE_READ') privilageMatrix.role.read = true;
		if (privilage === 'ROLE_WRITE') privilageMatrix.role.write = true;

		if (privilage === 'VEHICLE_READ') privilageMatrix.vehicle.read = true;
		if (privilage === 'VEHICLE_WRITE') privilageMatrix.vehicle.write = true;
		if (privilage === 'VEHICLE_APPROVE') {
			privilageMatrix.vehicle.approve = true;
			privilageMatrix.vehicle.read = true;
		}

		if (privilage === 'ORGANIZATION_READ') privilageMatrix.client.read = true;
		if (privilage === 'ORGANIZATION_WRITE') privilageMatrix.client.write = true;

		if (privilage === 'LOCATION_READ') privilageMatrix.location.read = true;
		if (privilage === 'LOCATION_WRITE') privilageMatrix.location.write = true;

		if (privilage === 'SERVICE_PROVIDER_READ') privilageMatrix.serviceProvider.read = true;
		if (privilage === 'SERVICE_PROVIDER_WRITE') privilageMatrix.serviceProvider.write = true;

		if (privilage === 'BOOKING_ORDER_READ') privilageMatrix.order.read = true;
		if (privilage === 'BOOKING_ORDER_WRITE') privilageMatrix.order.write = true;

		if (privilage === 'ZONE_READ') privilageMatrix.zone.read = true;
		if (privilage === 'ZONE_WRITE') privilageMatrix.zone.write = true;

		if (privilage === 'ROUTE_READ') privilageMatrix.routes.read = true;
		if (privilage === 'ROUTE_WRITE') privilageMatrix.routes.write = true;

		if (privilage === 'DOCK_READ') privilageMatrix.dock.read = true;
		if (privilage === 'DOCK_WRITE') privilageMatrix.dock.write = true;

		if (privilage === 'SETTINGS_READ') privilageMatrix.settings.read = true;
		if (privilage === 'SETTINGS_WRITE') privilageMatrix.settings.write = true;
	}

	return privilageMatrix;
};

export const getPrivilageListFromPrivilageMatrix = (matrix: PrivilageMatrix): PrivilageList => {
	let privilageList: PrivilageList = [];

	if (matrix.user) {
		if (matrix.user.read) privilageList.push('USER_READ');
		if (matrix.user.write) privilageList.push('USER_WRITE');
	}

	if (matrix.clientUser) {
		if (matrix.clientUser.read) privilageList.push('CLIENT_USER_READ');
		if (matrix.clientUser.write) privilageList.push('CLIENT_USER_WRITE');
	}

	if (matrix.serviceProviderUser) {
		if (matrix.serviceProviderUser.read) privilageList.push('SERVICE_PROVIDER_USER_READ');
		if (matrix.serviceProviderUser.write) privilageList.push('SERVICE_PROVIDER_USER_WRITE');
	}

	if (matrix.driver) {
		if (matrix.driver.read) privilageList.push('DRIVER_READ');
		if (matrix.driver.write) privilageList.push('DRIVER_WRITE');
		if (matrix.driver.approve) privilageList.push('DRIVER_APPROVE');
	}

	if (matrix.helper) {
		if (matrix.helper.read) privilageList.push('HELPER_READ');
		if (matrix.helper.write) privilageList.push('HELPER_WRITE');
		if (matrix.helper.approve) privilageList.push('HELPER_APPROVE');
	}

	if (matrix.role) {
		if (matrix.role.read) privilageList.push('ROLE_READ');
		if (matrix.role.write) privilageList.push('ROLE_WRITE');
	}

	if (matrix.vehicle) {
		if (matrix.vehicle.read) privilageList.push('VEHICLE_READ');
		if (matrix.vehicle.write) privilageList.push('VEHICLE_WRITE');
		if (matrix.vehicle.approve) privilageList.push('VEHICLE_APPROVE');
	}

	if (matrix.client) {
		if (matrix.client.read) privilageList.push('ORGANIZATION_READ');
		if (matrix.client.write) privilageList.push('ORGANIZATION_WRITE');
	}

	if (matrix.location) {
		if (matrix.location.read) privilageList.push('LOCATION_READ');
		if (matrix.location.write) privilageList.push('LOCATION_WRITE');
	}

	if (matrix.serviceProvider) {
		if (matrix.serviceProvider.read) privilageList.push('SERVICE_PROVIDER_READ');
		if (matrix.serviceProvider.write) privilageList.push('SERVICE_PROVIDER_WRITE');
	}

	if (matrix.order) {
		if (matrix.order.read) privilageList.push('BOOKING_ORDER_READ');
		if (matrix.order.write) privilageList.push('BOOKING_ORDER_WRITE');
	}

	if (matrix.zone) {
		if (matrix.zone.read) privilageList.push('ZONE_READ');
		if (matrix.zone.write) privilageList.push('ZONE_WRITE');
	}

	if (matrix.routes) {
		if (matrix.routes.read) privilageList.push('ROUTE_READ');
		if (matrix.routes.write) privilageList.push('ROUTE_WRITE');
	}

	if (matrix.dock) {
		if (matrix.dock.read) privilageList.push('DOCK_READ');
		if (matrix.dock.write) privilageList.push('DOCK_WRITE');
	}

	if (matrix.settings) {
		if (matrix.settings.read) privilageList.push('SETTINGS_READ');
		if (matrix.settings.write) privilageList.push('SETTINGS_WRITE');
	}

	return privilageList;
};

export const getPrivilageGroupName = (key: string) => {
	switch (key) {
		case 'driver':
			return 'Driver';
		case 'clientUser':
			return 'Client User';
		case 'serviceProviderUser':
			return 'Service Provider User';
		case 'helper':
			return 'Helper';
		case 'role':
			return 'Role';
		case 'serviceProvider':
			return 'Service Provider';
		case 'user':
			return 'User';
		case 'vehicle':
			return 'Vehicle';
		case 'client':
			return 'Client';
		case 'location':
			return 'Location';
		case 'order':
			return 'Order';
		case 'zone':
			return 'Zone';
		case 'routes':
			return 'Routes';
		case 'dock':
			return 'Dock';
		case 'settings':
			return 'Settings';
		default:
			return '';
	}
};

const privlageContext = createContext<PrivilageMatrix>(getPrivilageMatrixFromPrivilageList([]));

export const usePrivlage = () => useContext(privlageContext);

const { Provider } = privlageContext;

const PrivilegeProvider = ({ children }: { children: React.ReactNode }) => {
	const [accessMatrix, setAccessMatrix] = useState(getPrivilageMatrixFromPrivilageList([]));
	const [loading, setLoading] = useState(true);

	const { user } = useAuth();

	useEffect(() => {
		if (!user) {
			setLoading(false);
			return;
		}

		const privileges = user?.privileges;

		setAccessMatrix(getPrivilageMatrixFromPrivilageList(privileges as PrivilageList));
		setLoading(false);
	}, [user]);

	return <Provider value={accessMatrix}>{!loading && children}</Provider>;
};

export default PrivilegeProvider;
