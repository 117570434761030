import axios from './axios';

export type LoginPayload = {
	username: string;
	password: string;
};

class AuthService {
	static Instance() {
		return new AuthService();
	}

	async login(loginPayload: LoginPayload) {
		return axios.post('/login', loginPayload);
	}

	async getUserProfile() {
		return axios.get('/api/user/profile');
	}

	async getPrivileges() {
		return axios.get('/api/privilege/get/list');
	}

	async forgotPassword(email: string) {
		return axios.get(`/public/forgotPassword?email=${email}`);
	}

	async resetPassword(payload: any) {
		return axios.post(`/public/resetPasswordWithKey`, payload);
	}

	async logoutUser() {
		return axios.put('/api/user/logout');
	}
}

export default AuthService.Instance();
