import AuthProvider from 'provider/auth-provider';
import { AppRoutes } from 'routes';
import './App.css';

import GoogleMapProvider from 'provider/google-map-provider';
import PrivilegeProvider from 'provider/privilege-provider';
import { HashRouter } from 'react-router-dom';

function App() {
	return (
		<AuthProvider>
			<PrivilegeProvider>
				<GoogleMapProvider>
					<HashRouter>
						<AppRoutes />
					</HashRouter>
				</GoogleMapProvider>
			</PrivilegeProvider>
		</AuthProvider>
	);
}

export default App;
