import axios from 'axios';

function forceLogout() {
	localStorage.clear();
	window.location.reload();
}

class HttpService {
	static Instance() {
		let ax = axios.create({
			// baseURL: process.env.REACT_APP_API_URL,
			baseURL: '/',
			timeout: 300000,
			headers: {
				'Content-Type': 'application/json',
			},
		});

		ax.interceptors.request.use(config => {
			let token = localStorage.getItem('tms-token') || '';

			if (token) {
				config.headers.Authorization = 'Bearer ' + token;
			}
			return config;
		});

		ax.interceptors.response.use(undefined, err => {
			if (err.response.config.url.includes('/login')) return Promise.reject(err);

			if (err.response.status === 401 || err.response.status === 412) {
				return forceLogout();
			}

			if (err.response.status !== 401) {
				return Promise.reject(err);
			}
		});

		return ax;
	}
}

export default HttpService.Instance();
