import { Spin } from 'antd';
import { useAuth } from 'provider/auth-provider';
import { usePrivlage } from 'provider/privilege-provider';
import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Login from 'features/login';

const DockManagement = React.lazy(() => import('features/dock-management'));
const HomePage = React.lazy(() => import('features/home'));
const OrderManagement = React.lazy(() => import('features/order-management'));
const CreateOrder = React.lazy(() => import('features/order-management/create-order'));
const EditOrder = React.lazy(() => import('features/order-management/edit-order'));
const ClientManagement = React.lazy(() => import('features/org-management/client'));
const Locations = React.lazy(() => import('features/org-management/locations'));
const ServiceProvider = React.lazy(() => import('features/org-management/service-provider'));
const ZoneManagement = React.lazy(() => import('features/org-management/zone-management'));
const Reports = React.lazy(() => import('features/reports'));
const ResetPassword = React.lazy(() => import('features/reset-password'));
const RouteManagement = React.lazy(() => import('features/route-management'));
const CreateRoute = React.lazy(() => import('features/route-management/create-update-routes'));
const Settings = React.lazy(() => import('features/settings'));
const ClientUser = React.lazy(() => import('features/user-management/client-user'));
const Driver = React.lazy(() => import('features/user-management/driver'));
const Helper = React.lazy(() => import('features/user-management/helper'));
const RoleManagement = React.lazy(() => import('features/user-management/role-management'));
const ServiceProviderUser = React.lazy(() => import('features/user-management/service-provider'));
const User = React.lazy(() => import('features/user-management/user'));
const VehicleManagement = React.lazy(() => import('features/vehicle-management'));
const LayoutProvider = React.lazy(() => import('provider/layout-provider'));

const AppRoutes = () => {
	const privlage = usePrivlage();

	const { user } = useAuth();

	const {
		driver,
		clientUser,
		serviceProviderUser,
		helper,
		role,
		serviceProvider,
		user: userPrivlage,
		vehicle,
		client,
		location,
		order,
		zone,
		routes,
		dock,
		settings,
	} = privlage;

	if (!user) {
		return (
			<Routes>
				<Route path="/login" element={<Login />} />
				<Route path="/resetPassword" element={<ResetPassword />} />
				<Route path="*" element={<Navigate to={'/login'} />} />
			</Routes>
		);
	}

	return (
		<LayoutProvider>
			<Routes>
				<Route
					path="/"
					element={
						<Protected>
							<SuspenseLoader>
								<HomePage />
							</SuspenseLoader>
						</Protected>
					}
				/>
				{userPrivlage.read && (
					<Route
						path="/user-management/user"
						element={
							<Protected>
								<SuspenseLoader>
									<User />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}
				{clientUser.read && (
					<Route
						path="/user-management/client"
						element={
							<Protected>
								<SuspenseLoader>
									<ClientUser />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}
				{serviceProviderUser.read && (
					<Route
						path="/user-management/service-provider"
						element={
							<Protected>
								<SuspenseLoader>
									<ServiceProviderUser />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}
				{driver.read && (
					<Route
						path="/user-management/driver"
						element={
							<Protected>
								<SuspenseLoader>
									<Driver />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}
				{helper.read && (
					<Route
						path="/user-management/helper"
						element={
							<Protected>
								<SuspenseLoader>
									<Helper />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}
				{role.read && (
					<Route
						path="/user-management/role-management"
						element={
							<Protected>
								<SuspenseLoader>
									<RoleManagement />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}

				{vehicle.read && (
					<Route
						path="/vehicle-management"
						element={
							<Protected>
								<SuspenseLoader>
									<VehicleManagement />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}

				{client.read && (
					<Route
						path="/customer-management/clients"
						element={
							<Protected>
								<SuspenseLoader>
									<ClientManagement />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}
				{location.read && (
					<Route
						path="/customer-management/locations"
						element={
							<Protected>
								<SuspenseLoader>
									<Locations />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}
				{zone.write && (
					<Route
						path="/customer-management/zone-management"
						element={
							<Protected>
								<SuspenseLoader>
									<ZoneManagement />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}
				{serviceProvider.read && (
					<Route
						path="/customer-management/service-provider"
						element={
							<Protected>
								<SuspenseLoader>
									<ServiceProvider />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}
				{order.write && order.read && (
					<Route
						path="/order-management"
						element={
							<Protected>
								<SuspenseLoader>
									<OrderManagement />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}
				{order.write && (
					<Route
						path="/order-management/new"
						element={
							<Protected>
								<SuspenseLoader>
									<CreateOrder />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}
				{order.write && (
					<Route
						path="/order-management/view/:id"
						element={
							<Protected>
								<SuspenseLoader>
									<EditOrder />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}
				{routes.read && (
					<Route
						path="/route-management"
						element={
							<Protected>
								<SuspenseLoader>
									<RouteManagement />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}
				{routes.write && (
					<Route
						path="/route-management/new"
						element={
							<Protected>
								<SuspenseLoader>
									<CreateRoute />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}
				{routes.read && (
					<Route
						path="/route-management/view/:id"
						element={
							<Protected>
								<SuspenseLoader>
									<CreateRoute />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}
				{dock.read && (
					<Route
						path="/dock-management"
						element={
							<Protected>
								<SuspenseLoader>
									<DockManagement />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}
				{
					<Route
						path="/reports"
						element={
							<Protected>
								<SuspenseLoader>
									<Reports />
								</SuspenseLoader>
							</Protected>
						}
					/>
				}

				{settings.read && settings.write && (
					<Route
						path="/settings"
						element={
							<Protected>
								<SuspenseLoader>
									<Settings />
								</SuspenseLoader>
							</Protected>
						}
					/>
				)}

				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</LayoutProvider>
	);
};

function Protected({ children }: { children: JSX.Element }): JSX.Element {
	const { user } = useAuth();

	if (!user) {
		return <Navigate to="/" replace />;
	}

	return children;
}

function SuspenseLoader({ children }: { children: JSX.Element }) {
	return (
		<Suspense
			fallback={
				<div className="flex h-96 w-full items-center justify-center">
					<Spin size="large" />
				</div>
			}
		>
			{children}
		</Suspense>
	);
}

export default AppRoutes;
