import { Libraries, useJsApiLoader } from '@react-google-maps/api';
import { createContext, useContext } from 'react';

const MapContext = createContext({ isLoaded: false });

export const useMapContext = () => useContext(MapContext);

const { Provider } = MapContext;

const libraries: Libraries = ['core', 'places', 'maps', 'routes'];

const GoogleMapProvider = ({ children }: { children: React.ReactNode }) => {
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
		libraries: libraries,
	});

	return (
		<Provider
			value={{
				isLoaded,
			}}
		>
			{children}
		</Provider>
	);
};

export default GoogleMapProvider;
