import { Button, Form, Input, Modal, message } from 'antd';
import { useAuth } from 'provider/auth-provider';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService, { LoginPayload } from 'services/auth-service';

const Login = () => {
	const { setUser } = useAuth();
	const navigate = useNavigate();

	const [openForgotPassword, setOpenForgotPassword] = useState(false);

	const handleSubmit = async (values: LoginPayload) => {
		values.password = btoa(values.password);

		try {
			const { data } = await authService.login(values);

			if (!data.token) {
				throw new Error('Invalid credentials');
			}

			setUser(data);

			navigate('/');
		} catch (error) {
			message.error('Invalid credentials');
			console.log({ error });
		}
	};

	const handleForgotPassword = async (values: any) => {
		try {
			await authService.forgotPassword(values.email);
			message.success('Email sent successfully');
			setOpenForgotPassword(false);
		} catch (error: any) {
			if (error?.response?.status === 400) {
				message.error(error?.response?.data?.message);
			} else {
				message.error('Error occured while sending email');
			}
		}

		setOpenForgotPassword(false);
	};

	return (
		<div
			className="login-image flex min-h-screen flex-col items-center justify-center"
			style={{
				backgroundImage: `url(/images/login-bg.png)`,
				backgroundSize: 'contain',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
			}}
		>
			<div className="flex w-[500px] max-w-[90%] flex-col items-center justify-start rounded-3xl bg-white p-12">
				<span className="text-2xl font-bold">Login</span>
				<span className="mt-4">Hey, Enter your details to get sign in to your account</span>
				<Form onFinish={handleSubmit} className="mt-16 w-full">
					<Form.Item
						className="mb-10"
						name="username"
						required
						rules={[
							{ required: true, message: 'Please input your email!' },
							{ type: 'email', message: 'The input is not valid E-mail!' },
						]}
					>
						<Input placeholder="Enter your email" size="large" />
					</Form.Item>

					<Form.Item
						name="password"
						required
						rules={[
							{
								required: true,
								message: 'Please enter a password',
							},
							{
								min: 8,
								message: 'Password must be at least 8 characters',
							},
							{
								max: 20,
								message: 'Password must be at most 20 characters',
							},
							{
								pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,20}$/,
								message: 'Atleast 1 uppercase, 1 lowercase, 1 number and 1 special character required',
							},
						]}
					>
						<Input.Password placeholder="Password" size="large" />
					</Form.Item>

					<Form.Item>
						<Button onClick={() => setOpenForgotPassword(true)} type="link">
							Forgot Password?
						</Button>
					</Form.Item>

					<Form.Item className="mt-12">
						<Button className="w-full" htmlType="submit" type="primary" size="large">
							<span className="text-xl text-white">Login</span>
						</Button>
					</Form.Item>
				</Form>
			</div>

			<Modal
				open={openForgotPassword}
				title="Forgot Password"
				onCancel={() => setOpenForgotPassword(false)}
				footer={null}
				destroyOnClose
			>
				<Form onFinish={handleForgotPassword} className="mt-4 w-full">
					<Form.Item
						name="email"
						required
						rules={[
							{ required: true, message: 'Please input your email!' },
							{ type: 'email', message: 'The input is not valid E-mail!' },
						]}
					>
						<Input placeholder="Enter your email" size="large" />
					</Form.Item>

					<p className="mb-8 text-base">
						We will send you an email with a link to reset your password. If you don't get the email within a few
						minutes, check your spam folder or try again.
					</p>

					<Form.Item className="mt-12">
						<Button className="w-full" htmlType="submit" type="primary" size="large">
							<span className="text-xl text-white">Send Email</span>
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default Login;
